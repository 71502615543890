<template>
    <div class="job">
      <div class="jobTop">
        <img v-lazy="topBg" alt="" srcset="">
      </div>
      <!-- 求职者会遇到以下5大问题 -->
      <div class="jobProblem">
        <img v-lazy="problemTitle" alt="" srcset="">
        <img v-lazy="problemImg1" alt="" srcset="">
        <img v-lazy="problemImg2" alt="" srcset="">
      </div>
      <!-- 4大优势 -->
      <div class="jobAdvantage">
        <div class="jobAdvantage-top">
          <img v-lazy="advantageTitle" alt="" srcset="">
        </div>
        <div class="jobAdvantage-bottom">
          <div class="jobAdvantage-bottom-list">
            <img v-lazy="advantageImg" alt="">
            <!-- <div v-for="(item,index) in advantageList" :key="index" class="jobAdvantage-bottom-list-item">
              <img v-lazy="item.img" alt="">
              <p>{{ item.title }}</p>
              <p>{{ item.span1 }}</p>
              <p>{{ item.span2 }}</p>
            </div> -->
          </div>
        </div>
      </div>
      <!-- 服务流程 -->
      <div class="jobProcess">
        <img v-lazy="processTitle" alt="" srcset="">
        <img v-lazy="processImg" alt="" srcset="">
      </div>
      <!-- 案例展示 -->
      <div class="jobExhibition">
        <div class="jobExhibition-top">
          <img v-lazy="exhibitionTitle" alt="" srcset="">
        </div>
        <div class="jobExhibition-bottom">
            <div>优化前</div>
          <img v-lazy="exhibitionImg1" alt="" srcset="">
          <img v-lazy="exhibitionVs" alt="" srcset="">
          <div>优化后</div>
          <img v-lazy="exhibitionImg2" alt="" srcset="">
        </div>
      </div>
    </div>
  </template>
  <script>
  import { messageLeave } from '@/api/index.js'
  
  export default {
    data() {
      return {
          queryParams: {
          demandDescribe: '',
          name: '',
          phone: '',
        },
        topBg: require('../../assets/Mi/job/topBg.png'),
        problemTitle: require('../../assets/Mi/job/problem/title.png'),
        problemImg1: require('../../assets/Mi/job/problem/1.png'),
        problemImg2: require('../../assets/Mi/job/problem/2.png'),
        processTitle: require('../../assets/Mi/job/process/title.png'),
        processImg: require('../../assets/Mi/job/process/img.png'),
        advantageTitle: require('../../assets/Mi/job/advantage/title.png'),
        advantageImg: require('../../assets/Mi/job/advantage/img.png'),
        // advantageList: [
        //   {
        //     img: require('../../assets/Mi/job/advantage/1.png'),
        //     title: '互联网资深大咖',
        //     span1: '核心顾问均为10年',
        //     span2: '以上互联网行业资深大咖',
        //   },
        //   {
        //     img: require('../../assets/Mi/job/advantage/2.png'),
        //     title: '一对一沟通',
        //     span1: '一对一精聊沟通，挖掘',
        //     span2: '自身优势，定制求职策略',
        //   },
        //   {
        //     img: require('../../assets/Mi/job/advantage/3.png'),
        //     title: '梳理优化',
        //     span1: '简历结构化梳理，清晰',
        //     span2: '明了突出重点，排版简洁',
        //   },
        //   {
        //     img: require('../../assets/Mi/job/advantage/4.png'),
        //     title: '售后服务',
        //     span1: '30天内免费修改调整，精准',
        //     span2: '命中HR，附带面试及薪资辅导',
        //   },
        // ],
        exhibitionTitle: require('../../assets/Mi/job/exhibition/title.png'),
        exhibitionVs: require('../../assets/Mi/job/exhibition/vs.png'),
        exhibitionImg1: require('../../assets/Mi/job/exhibition/1.png'),
        exhibitionImg2: require('../../assets/Mi/job/exhibition/2.png'),
      }
    },
    methods:{
      messageLeave() {
        messageLeave({
          demandDescribe: this.queryParams.demandDescribe,
          name: this.queryParams.name,
          phone: this.queryParams.phone,
        }).then(res => {
          if (res.code == 200) {
            this.queryParams.demandDescribe = ''
            this.queryParams.name = ''
            this.queryParams.phone = ''
            this.$message({
              message: '提交成功',
              type: 'success'
            })
          } else {
            this.$message.error('提交失败');
          }
  
        })
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  .job {
    .jobTop {
      width: 100%;
      height: 377px;
      img {
        width: 100%;
        height: 377px;
      }
    }
    .jobProblem {
      margin-top: 31px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img:first-child {
        width: 289px;
        height: 31px;
      }
      img:nth-child(2) {
        margin-top: 26px;
        width: 337px;
        height:268px;
      }
      img:nth-child(3) {
        margin-top: 13px;
        width: 258px;
        height:214px;
      }
    }
    .jobProcess {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img:first-child {
        width: 182px;
        height: 31px;
      }
      img:last-child {
        margin-top: 21px;
        width: 343px;
        height: 272px;
      }
    }
    .jobAdvantage {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #444581;
      height: 370px;
      .jobAdvantage-top {
        margin-top: 30px;
        img {
          width: 182px;
          height: 31px;
        }
      }
      .jobAdvantage-bottom {
        margin-top: 35px;
        .jobAdvantage-bottom-list {
          display: flex;
          img {
              width: 253px;
              height: 231px;
            }
          .jobAdvantage-bottom-list-item:last-child {
            margin-right: 0px;
          }
          .jobAdvantage-bottom-list-item {
            margin-right: 102px;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 253px;
              height: 231px;
            }
            p:nth-child(2) {
              font-family: SourceHanSansCN-Medium;
              font-size: 30px;
              letter-spacing: 2px;
              color: #fff;
              margin-top: 29px;
              margin-bottom: 20px;
            }
            p {
              font-size: 16px;
              letter-spacing: 2px;
              color: #fff;
              line-height: 22px;
            }
          }
        }
      }
    }
    .jobExhibition {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: rgba($color: #A6CDFF, $alpha: 0.3);
    //   height: 1160px;
      margin-top: 24px;
      padding-bottom: 40px;
      .jobExhibition-top {
        margin-top: 30px;
        img {
          width: 160px;
          height: 31px;
        }
      }
      .jobExhibition-bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        div{
          width: 170px;
          height: 30px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 1px;
          color: #FFFFFF;
          letter-spacing: 0.5px;
          margin-bottom: 15px;
      }
      div:first-child{
          background-color:#B5B5B5 ;
      }
      div:nth-child(4){
        margin-top: 20px;
          background-color:#68AAFF ;
      }
      img:nth-child(2){
        width: 260px;
          height: 350px;
      }
      img:nth-child(3){
        margin-top: 20px;
        width: 60px;
          height: 60px;
      }
      img:nth-child(5){
        width: 260px;
          height: 350px;
      }
      }
    }
  }
  </style>